import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AuthGuard } from '../core/authentication/auth.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReferralComponent } from './referrals.component';
import { ReferralsService } from './referrals.service';

@NgModule({
  declarations: [
    ReferralComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    NgxSpinnerModule,
    RouterModule.forRoot([
      { path: 'referrals', component: ReferralComponent, canActivate: [AuthGuard]},
    ])
  ],
  providers: [ReferralsService],
  exports: [ReferralComponent]
})
export class ReferralModule { }
