import { NgModule  } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { ActivitiesComponent } from './ativities.component';
import { AuthGuard } from '../core/authentication/auth.guard';


const routes: Routes = [
  { path: 'activities', component: ActivitiesComponent, canActivate: [AuthGuard] },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ActivitiesRoutingModule { }
