import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../shared/config.data';
import { Schedule, Notification, AvailableOpportunity } from '../shared/models';
import { Observable } from 'rxjs';



@Injectable()
export class ActivitiesService {

  constructor(private http: HttpClient, private configService: ConfigurationService) {

  }

  public getSchedules(userId: string): Observable<Schedule[]> {
    return this.http.get<Schedule[]>(`${this.configService.getStaffApi()}assessor/${userId}/studies`);

  }

  public getAvailableOpportunities(userId: string): Observable<AvailableOpportunity[]> {
    return this.http.get<AvailableOpportunity[]>(this.configService.getStaffApi() + 'Users/GetQualifiedStudies', {
      params: {
        userGuid: userId
      }
    });
  }
  public updateNofication(notificationId: number): Observable<boolean> {
    return this.http.put<boolean>(this.configService.getStaffApi() + `notifications`,
      {
        confirmed: true,
        notificationId: notificationId
      });
  }
  public getNotifications(userId: number): Observable<Notification[]> {
    return this.http.get<Notification[]>(this.configService.getStaffApi() +  'notifications', {
      params: {
        userId: userId.toString(),
        unConfirmedOnly: 'true'
      }
    });
  }
  public createSurveyInstance(surveyId, userGuid: string, scheduleId: number, userKey: number): Observable<number> {
    return this.http.post<number>(this.configService.getSurveyApi() + `survey/${surveyId}/user`,
      {
        isTest: false,
        userId: userGuid,
        userKey: userKey,
        surveyId: surveyId,
        scheduleId: scheduleId
      });
  }

  public createParticipation(studyId: number, scheduleId: number, userKey: number): Observable<number> {
    return this.http.put<number>(this.configService.getStaffApi() + `participation/create`,
      {
        studyId: studyId,
        userId: userKey,
        scheduleId: scheduleId
      });
  }
}
