
import { Component, OnInit, Input } from '@angular/core';
import { AvailableOpportunity, SessionCode, StudyType } from '../../shared/models';
import { ConfigurationService } from '../../shared/config.data';
import { AuthService } from '../../core/authentication/auth.service';
import { ActivitiesService } from '../activities.service';

@Component({
  selector: 'opportunity',
  templateUrl: './opportunity.component.html',
  styles: [('./opportunity.component.css')],
})

export class OpportunityComponent implements OnInit {

  @Input() study: AvailableOpportunity;

  public url: string;
  public message: string;
  private clickable: boolean;

  constructor(
    private configService: ConfigurationService,
    private _authService: AuthService,
    private _activiesService: ActivitiesService) { }

  ngOnInit() {
    this.clickable = this.study.isSelfSchedule;

    if (!this.clickable) {
      if (this.study.hasScreener && (!this.study.participationId || 1 > this.study.participationId)) {
        this.clickable = true;
      }

      if (this.clickable && this.study.sessionCode === SessionCode.NeedsArticulation) {
        this.clickable = false;
      }

      if (!this.clickable && this.study.studyType == StudyType.PreliminaryScreener) {
        this.clickable = true;
        this.message = "For multiple studies";
      }

      if (!this.clickable) {
        this.message = `Please wait for a phone call for study ${this.study.displayName}`;
      }
    }
  }

  acceptOpportunity() {

    if (!this.clickable) {
      return;
    }

    const staffUrl = this.configService.getStaffWeb();
    const userId = this._authService.localUserId;

    this.message = "transferring";
    let url = `${staffUrl}assessor/schedule/?assessorId=${userId}&scheduleId=${this.study.scheduleId}&studyId=${this.study.studyId}`;

    if (this.study.participationId && 0 < this.study.participationId) {
      window.location.href = url;
    }
    else {
      if (this.study.hasScreener) {

        this._activiesService.createSurveyInstance(
          this.study.screenerId, this._authService.userId,
          this.study.scheduleId, this._authService.localUserId
        ).subscribe((result) => {
          url = `${this.configService.getSurveyWeb()}${this.study.screenerId}/survey/start?instanceId=${result}&autoStart=true`;
          window.location.href = url;
        });

      } else {

        if (!this.study.participationId) {
          this._activiesService.createParticipation(
            this.study.studyId, this.study.scheduleId, this._authService.localUserId
          ).subscribe((result) => {
            window.location.href = url;
          });
        } else {
          window.location.href = url;
        }
      }
    }
  };
}
