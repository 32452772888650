import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { ProfileContactInfoComponent } from './contactinfo/profile.contactinfo.component';
import { ProfileFamilyComponent } from './family/profile.family.component';
import { ProfileComponent } from './profile.component';
import { ProfileService } from './profile.service';
import { ProfileContactInfoEditComponent } from './contactinfo/profile.contactinfo.edit.component';
import { ProfileFamilyListComponent } from './family/profile.family.list.component';
import { AuthGuard } from '../core/authentication/auth.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotificationDeliveryTypePipe } from '../core/pipes/deliverytype-pipe';
import { CoreModule } from '../core/core.module';
import { ProfileUsernameComponent } from './contactinfo/profile.username.component';

@NgModule({
  declarations: [
    ProfileContactInfoComponent,
    ProfileFamilyComponent,
    ProfileComponent,
    ProfileContactInfoEditComponent,
    ProfileFamilyListComponent,
    ProfileUsernameComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    CoreModule,
    NgxSpinnerModule,
    RouterModule.forRoot([
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
      { path: 'profile/:id/edit', component: ProfileContactInfoEditComponent, canActivate: [AuthGuard] },
      { path: 'profile/:id/family', component: ProfileFamilyListComponent, canActivate: [AuthGuard] },
      { path: 'profile/:id/username', component: ProfileUsernameComponent, canActivate: [AuthGuard] }
    ])
  ],
  providers: [ProfileService],
  exports: [CommonModule]
})
export class ProfileModule { }
