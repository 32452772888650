import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AuthGuard } from '../core/authentication/auth.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { IncomeComponent } from './income.component';
import { IncomeService } from './income.service';

@NgModule({
  declarations: [
    IncomeComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    NgxSpinnerModule,
    RouterModule.forRoot([
      { path: 'income', component: IncomeComponent, canActivate: [AuthGuard]},
    ])
  ],
  providers: [IncomeService],
  exports: [CommonModule]
})
export class IncomeModule { }
