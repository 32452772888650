import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfigurationService {
   
  private key: string = "ic-settings";

  private result: any = null;
  constructor(private http: HttpClient) {
    let value = localStorage.getItem(this.key);
    if (value) {
      this.result = JSON.parse(value);
    }
  }
  public getScopes(): string {
    return "openid profile staffapi surveyapi";
  }
  public getClientId(): string {
    return "assessorweb";
  }
  public getAuthority(): string {
    return this.result.authority;
  }
  public getApiKey(): string {
    return this.result.apiKey;
  }
  public getStaffApi(): string {
    return this.result.staffApi;
  }
  public getStaffWeb(): string {
    return this.result.staffWeb;
  }
  public getResult() {
    return this.result;
  }
  public getSurveyApi() {
    return this.result.surveyApi;
  }
  public getSurveyWeb() {
    return this.result.surveyWeb;
  }
  public getUserName() {
    return this.result.userName;
  }
  public getUserId(): number {
    return this.result.userId;
  }
  public getCustomHeaderJs(): string {
    return this.result? this.result.customHeaderJs: "";
  }
  public getConfirmJs(): string {
    return this.result? this.result.confirmJs: "";
  }
  public getAccesstoken(): string {
    return this.result.accessToken;
  }
  public load() {

    return this.http.get('/home/GetConfigurationData').pipe(map((result) => {
      localStorage.setItem(this.key, JSON.stringify(result));
      this.result = result;
      return result;
    })).toPromise();
    
  }
}
