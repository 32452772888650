import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/authentication/auth.service';
import { FamilyWidget } from '../../shared/models';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'profile-family',
  templateUrl: './profile.family.card.html'
})

export class ProfileFamilyComponent implements OnInit {

  userId: number;
  familyWidget: FamilyWidget;

  constructor(authService: AuthService, private _profileService: ProfileService) {
    this.userId = authService.localUserId;
    this.familyWidget = { familyCount: 0, confirmedCount: 0, unconfirmedCount: 0 } as FamilyWidget;
  }

  ngOnInit() {
    this._profileService.getFamilyWidget(this.userId.toString())
      .subscribe((val) => {
        if (val === undefined || val === null) {
          val = { familyCount: 0, confirmedCount: 0, unconfirmedCount: 0 } as FamilyWidget;
        }
        this.familyWidget = val;
        if (this.familyWidget.familyCount === undefined || this.familyWidget.familyCount === null) {
          this.familyWidget.familyCount = 0;
        }
        if (this.familyWidget.confirmedCount === undefined || this.familyWidget.confirmedCount === null) {
          this.familyWidget.confirmedCount = 0;
        }
        if (this.familyWidget.unconfirmedCount === undefined || this.familyWidget.unconfirmedCount === null) {
          this.familyWidget.unconfirmedCount = 0;
        }
      });
  }
}
