import { Component, OnInit } from '@angular/core';
import { ProfileService } from './profile.service';
import { Survey, UserSurveyDto, UserProfile } from '../shared/models';
import { AuthService } from '../core/authentication/auth.service';
import { ConfigurationService } from '../shared/config.data';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styles: [('./profile.component.scss')]
})

export class ProfileComponent implements OnInit {

  constructor(private _profileService: ProfileService, private _authService: AuthService,
    private _configService: ConfigurationService, private spinner: NgxSpinnerService) {  }

  profiles: UserSurveyDto[];
  profileSurveys: Survey[];
  userProfile: UserProfile;


  ngOnInit() {
    this.spinner.show();

    this._profileService.getUserProfileCompletion(this._authService.userId).subscribe((val) => {
      this.profiles = val;
    });
    this._profileService.getSurveyData().subscribe((val) => {
      this.profileSurveys = val;
    });
    this._profileService.getProfileInfo(this._authService.userId).subscribe((val) => {
      this.userProfile = val[0];

      if (this.userProfile.allowSMS === undefined || this.userProfile.allowSMS === null) {
        this.userProfile.allowSMS = false;
      }
      if (this.userProfile.isBadSMS === undefined || this.userProfile.isBadSMS === null) {
        this.userProfile.isBadSMS = false;
      } if (this.userProfile.isBadEmail === undefined || this.userProfile.isBadEmail === null) {
        this.userProfile.isBadEmail = false;
      }
    });

    setTimeout(() => {
      this.spinner.hide();
    }, 1000);

  }
  async updateProfile(survey: Survey) {
    let instanceId: number = await this._profileService.createSurveyInstance(survey.id, this._authService.userId);
    const url =
      `${this._configService.getSurveyWeb()}${survey.id}/survey/start?returnUrl=${window.location.href}&instanceId=${instanceId}&autoStart=true&restart=true`;
    //console.log(url);
    window.location.href = url;
  }
  getCompletedSurvey(survey: Survey): UserSurveyDto {
    const completion = this.profiles.find((item) => {
      return item.surveyId == survey.id;
    });
    return completion;
  }
  hasEndDate(survey: Survey) {
    return (this.getCompletedSurvey(survey));
  }
  getEndDate(survey: Survey) {
    const completion = this.getCompletedSurvey(survey);
    return completion.endDate;
  }
}
