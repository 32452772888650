import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SharedModule } from '../shared/shared.module';
import { AccountRoutingModule } from './account.routing-module';
import { AuthService } from '../core/authentication/auth.service';
import { RouterModule } from '@angular/router';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthSilentCallbackComponent } from './auth-silent/auth-silent.component';
@NgModule({
  declarations: [LoginComponent, RegisterComponent, AuthCallbackComponent, AuthSilentCallbackComponent],
  providers: [AuthService],
  imports: [
    CommonModule,
    FormsModule,
    AccountRoutingModule,
    SharedModule,
    
  ],
  exports: [RouterModule]
})
export class AccountModule { }
