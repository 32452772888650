import { Component, OnInit } from '@angular/core';
import { LedgerSummary, LedgerDetail } from '../shared/models';
import { AuthService } from '../core/authentication/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IncomeService } from './income.service';

@Component({
  selector: 'income',
  templateUrl: './income.component.html',
  styles: [('./income.component.scss')]
})

export class IncomeComponent implements OnInit {

  incomeDetail: LedgerSummary;

  selectedYear: number;
  incomeYears: number[];

  constructor(
    private _authService: AuthService,
    private spinner: NgxSpinnerService,
    private _incomeService: IncomeService) {
  }

  ngOnInit() {
    this.incomeYears = [];
    const year = new Date().getFullYear();

    this.selectedYear = year;
    this.incomeYears.push(year);
    this.incomeYears.push(year - 1);

    this.loadYear(year);
  }
  getYtdTotal(incomeDetail: LedgerSummary) {
    let total = 0;
    if (!incomeDetail.ledgerDetail) {
      return total;
    }
    incomeDetail.ledgerDetail.forEach((detail: LedgerDetail) => {
      total += detail.pointsEarned;
    });
    return total;
  }
  loadYear(year: number) {
    this.spinner.show();
    this._incomeService
      .getIncome(this._authService.localUserId, year)
      .subscribe((val) => {
        this.spinner.hide();
        this.incomeDetail = val;
      })
  }
}
