import { Injectable } from '@angular/core';
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { IHttpError } from '../shared/models';


@Injectable()
export class IcToastrService  {
    baseUrl: string;
    userId: number;
  constructor(private _toastr: ToastrService) {

  }
 
  show(message?: string, title?: string): ActiveToast<any> {
    return this._toastr.show(message, title);
  }
  /** show successful toast */
  success(message?: string, title?: string): ActiveToast<any> {
    return this._toastr.success(message, title);
  }
  /** show error toast */
  error(message?: string): ActiveToast<any> {
    return this._toastr.error(message);
  }
  httpError(error?: IHttpError): ActiveToast<any> {
    if (error.data) {
      return this._toastr.error(error.data.message);
    }
    let custom = <any>error;

    if (custom.error) {
      return this._toastr.error(custom.error.message);
    }
    return this._toastr.error(custom.message);
    
  }

 
}
