import { IKeyValuePair, NotificationDeliveryType } from "../core/models";

export class Schedule {
  id: number;
  studyId: number;
  studyName: string;
  facilityName: string;

  sessionDate: Date;
  sessionId?: number;
  isCancelled: boolean;
  isPostponed: boolean;
  isScheduled: boolean;
  isScheduleOpen: boolean;
  isSelfSchedule: boolean;
  sessionCode: SessionCode;
  sessionDescription: string;
  isChangeSessionAvailable: boolean;
  isScheduleOpenToPostponed: boolean;
}
export class Notification {
  id?: number;
  message?: string;
  eventType?: SubscriptionType;
}
export enum SubscriptionType {
  StudyCreated = 1,
  StudyActivated = 2,
  StudyCompleted = 3,
  QuotaFull = 4,
  ProfileUpdateRequested = 5
}
export enum ImageType {
  Logo = 1,
  Birthday = 2
}
export enum SessionCode {
  NeedsArticulation = 25,
  Pending = 50,
  Postponed = 980,
  WaitingList = 985,
  CannotAttend = 991,
  Cancelled = 992,
  Rejected = 993,
  BarFromStudy = 994,
  StudyCancelled = 999
}

export class Study {
  id: number;
  name: string;
  startDate: Date;
  endDate: Date;
  hasScreener: boolean;
}
export class Survey {
  id: number;
  name: string;
  type: any;
}
export class FamilyWidget {
  familyCount: number;
  confirmedCount: number;
  unconfirmedCount: number;
}
export class FamilyMember {
  id: number;
  userId: number;
  familyId: number;
  fullName: string;
  userName: string;
  familyName: string;
  relationship: number;
  relationshipName: any;
  isConfirmed: boolean;
}
export class FamilyRequest {
  userId: number;
  userName: string;
  relationship: number;
}
export class UserSurveyDto {
  id: number;
  surveyId: number;
  scheduleId: number;
  endDate: Date;
  surveyName: string;
}
export class UserRegisterValidateCommand {
  public userName: string;
  public email: string;
  public firstName: string;
  public password: string;
  public confirmPassword: string;
  public lastName: string;
  public dateOfBirth: Date;
}
export enum HearAboutUs {
  Friend = 1,
  Organization = 2
}
export class RegisterRequest {
  public id?: string;
  public userName?: string;
  public email?: string;
  public firstName?: string;
  public isMale?: number;
  public password?: string;
  public confirmPassword?: string;
  public lastName?: string;
  public dateOfBirth?: Date;
  public address1?: string;
  public address2?: string;
  public stateAbbreviation?: string;
  public zipCode?: string;
  public city?: string;
  public homePhone?: string;
  public cellPhone?: string;
  public workPhone?: string;
  public allowText?: boolean;
  public referralId?: number;
  public referredByTextbox?: string;
  public redirectUrl?: string;
  public useFundraising?: boolean;
  public hearAboutUsId?: number;
  public organizationId?: number;
  public parentUserName?: string;
  public referralName?: string;
  public isMinor(): boolean {
    const dob = new Date(this.dateOfBirth);
    const current = new Date();

    return dob.getFullYear() > (current.getFullYear() - 18)
  }
}
export class HearAboutUsDetail {
  public displayOrder: number;
  public id: number;
  public isActive: boolean;
  public isSystem: boolean;
  public name: string;
}
export class OrganizationDetail {
  public id: number;
  public organizationName: string;
}
export class StateDetail {
  public id: number;
  public stateName: string;
}
export class UserPreferences {
  invitations?: NotificationDeliveryType;
  reminders?: NotificationDeliveryType;
  invitationsConfirmationDate?: Date;
  invitationsConfirmationID?: number;
  remindersConfirmationID?: number;
  remindersConfirmationDate?: Date;
}
export class UserNameRequest {
  public userId?: number;
  public username?: string;
  public newUsername?: string;
  public confirmUsername?: string;
}
export class UserProfile {
  public id?: string;
  public email?: string;
  public city?: string;
  public state?: string;
  public zipCode?: string;
  public zipPlus?: string;
  public address1?: string;
  public address2?: string;
  public cellPhone?: string;
  public homePhone?: string;
  public workPhone?: string;
  public modifiedDate?: Date;
  public extension?: string;
  public mobileCarrierId?: number;
  public primaryPhoneType?: PhoneType;

  public userName?: string;
  public fullName?: string;
  public lastName?: string;
  public firstName?: string;

  public isBadSMS?: boolean;
  public isBadEmail?: boolean;

  public allowSMS?: boolean;
  public allowText?: boolean;
  public preferences?: UserPreferences;
  public twoFactorEnabled?: boolean;
}
export enum SessionStatus {
  Opened = 1,
  ClosedUnlocked,
  ClosedLocked,
  Cancelled
}
export enum ScheduleWorkflowState {
  StudyInfo = 0,
  Terms = 1,
  Decline = 2,
  Scheduling = 3,
  GiftCardSelection = 4,
  Confirm = 5
}
export enum PhoneType {
  Home = 0,
  Cell = 1,
  Work = 2,
  Fax = 3
}
export enum StudyType {
  ConsumerTest = 1,
  PreliminaryScreener = 2,
  TrainedPanel = 3,
  Other = 4
}

export class GiftCard {
  id?: number;
  name: string;
  isActive: boolean;
  displayOrder?: number;
  isSelected?: boolean;
}

export class SessionDetails {
  id?: number;
  displayOrder?: number;
  maxPerSession?: number;
  sessionNumber?: number;
  sessionDescription?: string;
  sessionDate?: Date;
}

export class RegistrationSettingsDetail {
  public useFundRaising: boolean;
  public states: string[];
  public hearAboutUs: IKeyValuePair;
  public organizations: IKeyValuePair;
}

export class AvailableOpportunity {
  startDate: Date;
  endDate: Date;
  hasScreener: boolean;
  displayName: string;
  specialNote?: string;
  studyId: number;
  userId: number;
  projectNumber: string;
  scheduleId: number;
  isSelfSchedule: boolean;
  dataCollectionEndDate: Date;
  dataCollectionStartDate: Date;
  screenerId?: number;
  studyType?: StudyType;
  participationId?: number;
  sessionCode?: SessionCode;
}
export class SchedulerData {
  usePregnancyQuestion: boolean;
  startDate: Date;
  endDate: Date;
  hasScreener: boolean;
  oneStudyHouseHold: boolean;
  displayName: string;
  specialNote?: string;
  userCompensationType: number;
  userCompensationAmount: number;
  employeeCompensationAmount: number;
  studyId: number;
  sessionId?: number;
  userId: number;
  projectNumber: string;
  scheduleId: number;
  sessionLengthDescription: string;
  dataCollectionEndDate: Date;
  dataCollectionStartDate: Date;
  termsConditions?: any;
}
export class SchedulerUserResponse {

  studyId: number;
  sessionId: number;
  isPregnant: boolean = false;
  giftCard: GiftCard;
  agreedToTerms?: boolean = true;
  isMultipleHousehold: boolean = false;
  accepted: boolean = true;
  cannotAttendSession?: boolean = false;
  session: SessionDetails;
  userId: number;
  isValid(): boolean {
    if (!this.userId || this.studyId) {
      return false;
    }
    return true;
  }
}
export class SchedulerResponse {
  studyId: number;
  accepted: boolean;
  message: string;
}
export class ReferralRequest {
  firstName: string = '';
  lastName: string;
  email: string;
  referredByUserId: number;
}
export class ReferralSummary {
  referralById?: number;
  createDate?: Date;
  firstName?: string;
  lastName?: string;
  email?: string;
}
export class LedgerSummary {
  userId?: number;
  ledgerYear?: Date;
  cashEarnings?: number;
  pointsBalance?: number;
  ledgerDetail?: LedgerDetail[];
}
export class LedgerDetail {
  studyId?: number;
  studyName?: number;
  ledgerType?: LedgerType;
  displayName?: number;
  orginizationId?: number;
  organizationName?: string;
  referralUserId?: number;
  referralName?: string;
  pointsEarned?: number;
  pointsRedeemed?: number;
  cashAmount?: number;
  lineDescription?: string;
  userBatchId?: number;
  createDate?: Date;
  isRegistrationProcessed?: boolean;

}
export enum LedgerType {
  Entry = 0,
  Registration,
  Referal,
  Study,
  Redemption
}
export interface IHttpError {
  data: IDataMessage;
  error?: IDataMessage;
}

export enum HttpStatusCode {
  InternalError = 500,
  NotFound = 404,
  Unauthorized = 401
}

export interface IDataMessage {
  message: string;
  modelState?: any;
  status: HttpStatusCode;
}
export class IStatusRequest {
  reason: string;
  assessorId: number;
  requestedBy: number;
  endDate: Date;
  action: number;
  modifiedDate: Date;
}
