import { Component } from '@angular/core';
import { AuthService } from '../core/authentication/auth.service';
import { ConfigurationService } from '../shared/config.data';
import { ImageType } from '../shared/models';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  logoUrl: string;
  isAuthenticated: boolean;
  user: any;

  constructor(public authService: AuthService, private _configuration: ConfigurationService) {
    this.isAuthenticated = this.authService.isAuthenticated();
    this.authService.authNavStatus$.subscribe(() => {
      this.isAuthenticated = this.authService.isAuthenticated();
      this.user = this.authService.name;

    });

    this.logoUrl = `${_configuration.getStaffApi()}files?imageType=${ImageType.Logo}`;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  logout() {
    this.authService.logOut();
    return false;
  }
  login() {
    this.authService.login();
    return false;
  }
}
