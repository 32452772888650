import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { UserManager, UserManagerSettings, User } from 'oidc-client';
import { BehaviorSubject } from 'rxjs';
import { ConfigurationService } from '../../shared/config.data';
import { BaseService } from "../../shared/base.service";


@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();
  private localIdKey: string = "localId";
  private user: User | null;
  private manager: any;
  constructor(private http: HttpClient, private configService: ConfigurationService) {
    super();


  }
  load() {

    return this.configService.load().then(() => {
      this.manager = new UserManager(this.getIdentitySettings());
      return this.manager.getUser().then((user) => {
        this.user = user;
        this._authNavStatusSource.next(this.isAuthenticated());
      });
    });
  }
  login() {
    return this.manager.signinRedirect();
  }
  signInSilent() {
    return this.manager.signinSilentCallback();
  }
  logOut() {
    this.manager.signoutRedirect();
  }
  async completeAuthentication() {
    await this.manager.signinRedirectCallback();
    this.user = await this.manager.getUser();

    let localId = await this.getLocalUserId(this.userId);
    localStorage.setItem(this.localIdKey, JSON.stringify(localId[0]));

    this._authNavStatusSource.next(this.isAuthenticated());
  }
  async getLocalUserId(userGuid: string): Promise<any> {
    return await this.http.get<any>(this.configService.getStaffApi() + "users", { params: { userGuid: userGuid } }).toPromise();
  };

  register(userRegistration: any) {
    return this.http.post('/account', userRegistration).pipe(catchError(this.handleError));
  }

  isAuthenticated(): boolean {

    return this.user != null && !this.user.expired;
  }

  async getAuthorizationHeaderValue() {
    if (this.user) {
      //console.log(this.user.access_token);
      return `${this.user.token_type} ${this.user.access_token}`;
    }
    return "";
  }

  setUsername(username: string): void {
    if (this.user != null) { this.user.profile.name = username };
  }
  get name(): string {
    return this.user != null ? this.user.profile.name : '';
  }
  get localUserId(): number {
    let user = JSON.parse(localStorage.getItem(this.localIdKey));
    return user.id;
  }
  get userId(): string {
    //console.log(this.user);
    return this.user != null ? this.user.profile.sub : '';
  }

  signout() {
    this.manager.signoutRedirect();
  }
  getIdentitySettings(): UserManagerSettings {
    const settings = {
      authority: this.configService.getAuthority(),
      client_id: this.configService.getClientId(),
      redirect_uri: window.location.origin + '/auth-callback',
      scope: this.configService.getScopes(),
      loadUserInfo: true,
      silent_redirect_uri: window.location.origin + '/auth-silent-callback',
      response_type: "token id_token",
      post_logout_redirect_uri: window.location.origin,

    };
    //console.log(settings);
    return settings;
  }
}
