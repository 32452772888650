import { Component, OnInit, Input } from '@angular/core';
import { UserProfile } from 'src/app/shared/models';
import { AuthService } from 'src/app/core/authentication/auth.service';

@Component({
  selector: 'profile-contactinfo',
  templateUrl: './profile.contactinfo.card.html'
})

export class ProfileContactInfoComponent implements OnInit {

  userId: string;
  @Input() profile: UserProfile;
  constructor(authService: AuthService) {
    this.userId = authService.userId;
  }
  ngOnInit() { }
}
