
import { Component, OnInit, Input } from '@angular/core';
import { Schedule, Study, SessionCode } from '../../shared/models';
import { ConfigurationService } from '../../shared/config.data';
import { AuthService } from '../../core/authentication/auth.service';

@Component({
  selector: 'schedule',
  templateUrl: './schedule.component.html',
  styles: [('./schedule.component.css')],
})

export class ScheduleComponent implements OnInit {

  public url: string;
  public message: string;
  public hasSession: boolean;
  public scheduleText: string;
  public showClosedText: boolean;

  public closedText: string = `This schedule is currently closed to changes. Please call our office to cancel or change your appointment time.`;

  @Input() schedule: Schedule;

  constructor(
    private configService: ConfigurationService,
    private _authService: AuthService) { }

  ngOnInit() {

    const userId = this._authService.localUserId;
    const staffUrl = this.configService.getStaffWeb();

    this.showClosedText = false;
    this.hasSession = (null != this.schedule.sessionId && 0 < this.schedule.sessionId)
    this.url = `${staffUrl}assessor/schedule/?assessorId=${userId}&scheduleId=${this.schedule.id}&studyId=${this.schedule.studyId}&showSessions=true`;

    if (this.hasSession) {
      this.url += `&sessionId=${this.schedule.sessionId}`;
    }
    this.scheduleText = this.getScheduleDetailText();
  }

  getClass(): string {
    if (this.schedule.isCancelled) {
      return 'alert-danger';
    }
    else if (this.schedule.isPostponed) {
      return 'btn-warning';
    }
    return 'btn';
  }

  getScheduleDetailText(): string {
    if (this.schedule.isCancelled) {
      return 'This study has been cancelled';
    } else if (this.schedule.isPostponed &&
      !this.schedule.isScheduleOpenToPostponed) {
      return 'This study has been postponed';
    } else if (this.schedule.sessionCode === SessionCode.Pending) {
      return 'Please wait for a phone call';
    } else if (this.hasSession && (!this.schedule.isChangeSessionAvailable || !this.schedule.isScheduleOpen)) {
      return 'Call our office to change or cancel: ' + this.schedule.sessionDescription;
    } else if (this.schedule.sessionDate) {
      return this.schedule.sessionDescription;
    } else if (this.schedule.isScheduleOpen &&
      this.schedule.sessionCode == SessionCode.Postponed) {
      return 'Rescheduled sessions are available';
    } else if (!this.schedule.isSelfSchedule) {
      return 'Call our office to change or cancel';
    } else if (this.schedule.isScheduled && !this.hasSession) {
      return 'Sessions are available';
    }
    return '';
  }

  changeSchedule() {
    if (!this.schedule.isSelfSchedule) {
      this.scheduleText = 'Call our office to change or cancel';
      return;
    }
    if (this.schedule.sessionCode === SessionCode.NeedsArticulation) {
      this.scheduleText = 'Please wait for a phone call';
      return;
    }
    if (!this.schedule.isScheduleOpen) {
      this.scheduleText = 'Scheduling is not open at this time';
      return;
    }
    if (this.schedule.isChangeSessionAvailable) {
      this.scheduleText = "Transferring to schedule";
      window.location.href = this.url;
    } else {
      this.showClosedText = true;
      return;
    }
  }
}
