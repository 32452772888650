import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';

import { ActivitiesRoutingModule } from './activities.routing-module';
import { AuthService } from '../core/authentication/auth.service';
import { ActivitiesComponent } from './ativities.component';
import { OpportunityComponent } from './opportunity/opportunity.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { ActivitiesService } from './activities.service';

@NgModule({
  declarations: [ScheduleComponent, OpportunityComponent, ActivitiesComponent],
  providers: [AuthService, ActivitiesService],
  imports: [
    CommonModule,
    FormsModule,
    ActivitiesRoutingModule,
    SharedModule,

  ],
  exports: [RouterModule]
})
export class ActivitiesModule { }
