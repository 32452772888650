import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Form, NgForm } from '@angular/forms';
import { ProfileService } from '../profile.service';
import { AuthService } from '../../core/authentication/auth.service';
import { IHttpError, UserNameRequest } from '../../shared/models';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common'


@Component({
  selector: 'username',
  templateUrl: './profile.username.component.html',
  styles: [('./profile.username.component.scss')]
})

export class ProfileUsernameComponent implements OnInit {

  usernameRequest: UserNameRequest;

  public userId: number;
  public usernameForm: Form;

  constructor(
    private _toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private _authService: AuthService,
    private _profileService: ProfileService,
    private location: Location) {
  }

  async ngOnInit() {
    this.userId = this._authService.localUserId;
    this.usernameRequest = {} as UserNameRequest;

    await this.loadRequest();
  }

  async loadRequest() {

    this.spinner.show();
    await this._profileService.getUsernameRequest(this.userId).subscribe((val) => {
      this.usernameRequest = val as UserNameRequest;
      this.spinner.hide();
    }, (error: IHttpError) => {
      this.spinner.hide();
      this._toastr.error("Error occured");
    });
  }

  goBack() {
    this.location.back();
  }

  async onSubmit(form: NgForm) {

    if (form.invalid) {
      return;
    }

    if (this.IsValid()) {
      this.spinner.show();
      this._profileService.getUsernameIsTaken(this.userId, this.usernameRequest.confirmUsername)
        .subscribe(async (val) => {
          if (!val) {
            await this._profileService.updateUsername(this.usernameRequest).subscribe((val) => {
              this._authService.setUsername(this.usernameRequest.confirmUsername);
              this.spinner.hide();
              this.goBack();
            }, (error: IHttpError) => {
              this.spinner.hide();
              this._toastr.error("Error occured");
            });
          } else {
            this.spinner.hide();
            this._toastr.error("Username is already taken.  Please choose another");
          }
        }, (error: IHttpError) => {
          this.spinner.hide();
          this._toastr.error("Error occured");
        });
    }
  }

  IsValid(): boolean {
    if (!this.usernameRequest.newUsername || 6 > this.usernameRequest.newUsername.length) {
      this._toastr.warning("Please provide a new user name of at least 6 characters");
      return false;
    }
    if (this.usernameRequest.newUsername.indexOf(' ') >= 0) {
      this._toastr.warning("Username cannot have spaces");
      return false;
    }
    if (!this.usernameRequest.confirmUsername || this.usernameRequest.confirmUsername != this.usernameRequest.newUsername) {
      this._toastr.warning("Confirm user name must match New user name");
      return false;
    }
    return true;
  }
}
