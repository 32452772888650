import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService} from  '../shared/config.data';
import { ReferralSummary, ReferralRequest} from '../shared/models';
import { Observable } from 'rxjs';


@Injectable()
export class ReferralsService  {
    baseUrl: string;
    userId: number;
    constructor(protected _http: HttpClient, private _configuration: ConfigurationService) {
        this.baseUrl = _configuration.getStaffApi();
        this.userId = _configuration.getUserId();
  }
  public createReferral(referralRequest: ReferralRequest): Observable<boolean> {
    let actionUrl = `${this.baseUrl}referrals`;
    return this._http.post<boolean>(actionUrl, referralRequest);
  }
  public getReferrals(userId: number): Observable<ReferralSummary[]> {
    let actionUrl = `${this.baseUrl}referrals?userId=${userId} `;
    return this._http.get<ReferralSummary[]>(actionUrl);
		}
}
