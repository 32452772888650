import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FamilyMember, FamilyRequest, IHttpError } from '../../shared/models';
import { ProfileService } from '../profile.service';
import { AuthService } from '../../core/authentication/auth.service';
import { IKeyValuePair } from '../../core/models';


@Component({
  selector: 'profile-family-list',
  templateUrl: './profile.family.list.card.html'
})

export class ProfileFamilyListComponent implements OnInit {

  familyRequest: FamilyRequest;
  familyMembers: FamilyMember[];

  relationships: IKeyValuePair[];

  userId: number;
  userName: string;
  submitted: boolean;

  addFamily: boolean;

  constructor(
    private route: ActivatedRoute,
    private _profileService: ProfileService,
    private spinner: NgxSpinnerService,
    private _toastr: ToastrService,
    private _authService: AuthService) { }

  ngOnInit() {
    this.spinner.show();
    this.submitted = false;
    this.addFamily = false;

    this.familyRequest = {
      userId: 0,
      userName: "",
      relationship: -1,
    } as FamilyRequest;

    this.familyRequest.userId = this._authService.localUserId;

    this.relationships = [] as IKeyValuePair[];
    this.relationships.push({ key: 0, value: "Spouse (Partner)" } as IKeyValuePair);
    this.relationships.push({ key: 1, value: "Former Spouse (Partner)" } as IKeyValuePair);
    this.relationships.push({ key: 2, value: "Child" } as IKeyValuePair);
    this.relationships.push({ key: 3, value: "Parent (Guardian)" } as IKeyValuePair);

    this.loadFamily();
  }

  confirmFamily(familyMember: FamilyMember) {
    this.spinner.show();

    let id;
    this.route.paramMap.subscribe(params => {
      id = params.get("id");
    });

    this._profileService.confirmFamilyMember(id, familyMember.id).subscribe((val) => {
      familyMember.isConfirmed = val;;
      this.spinner.hide();
    }, (error: IHttpError) => {
      this.spinner.hide();
      this._toastr.error(error.data.message);
    });
  }

  deleteFamily(userFamilyId: number) {
    this.spinner.show();

    let id;
    this.route.paramMap.subscribe(params => {
      id = params.get("id");
    });

    var reload = false;
    this._profileService.deleteFamilyMember(id, userFamilyId)
      .subscribe((val) => {
        reload = val;
        if (reload) { this.loadFamily(); } else {
          this.spinner.hide();
        }
      }, (error: IHttpError) => {
        this.spinner.hide();
        this._toastr.error(error.data.message);
      });
  }

  loadFamily() {
    this.spinner.show();

    let id;
    this.route.paramMap.subscribe(params => {
      id = params.get("id");
    });

    this.resetForm();

    this._profileService.getFamilyMembers(id).subscribe((val) => {
      this.familyMembers = val;
      this.spinner.hide();
    }, (error: IHttpError) => {
      this.spinner.hide();
      this._toastr.error(error.data.message);
    });
  }

  toggleAdd() {
    this.addFamily = !this.addFamily;
    this.resetForm();
  }

  onSubmit(form: NgForm) {

    if (form.invalid) {
      return;
    }

    //console.log('trying to submit');

    this.spinner.show();

    let id;
    this.route.paramMap.subscribe(params => {
      id = params.get("id");
    });

    //console.log(this.familyRequest);

    this._profileService.requestFamilyMember(id, this.familyRequest)
      .subscribe((val) => {
        if (val == 1) {

          this.cancelAdd();
          this.submitted = true;
          this._toastr.success("Created family member");

          this.loadFamily();
        } else {
          this.spinner.hide();
          this.submitted = false;
          this._toastr.warning("Username not found");
        }
      }, (error: IHttpError) => {
        this.spinner.hide();
        this._toastr.error(error.data.message);
      });
  }

  resetForm() {
    this.submitted = false;
    this.familyRequest.userName = "";
    this.familyRequest.relationship = -1;
  }
  cancelAdd() {
    this.addFamily = false;
    this.resetForm();
  }

}
