import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../shared/config.data';
import { LedgerSummary } from '../shared/models';
import { Observable } from 'rxjs';


@Injectable()
export class IncomeService {
  baseUrl: string;
  userId: number;
  constructor(protected _http: HttpClient, private _configuration: ConfigurationService) {
    this.baseUrl = _configuration.getStaffApi();
    this.userId = _configuration.getUserId();
  }
  public getIncome(userId: number, year: number): Observable<LedgerSummary> {
    let actionUrl = `${this.baseUrl}ledger`;
    return this._http.get<LedgerSummary>(actionUrl, {
      params: {
        userId: `${userId}`,
        year: `${year}`,
        includeDetail: 'true'
      }
    }
    );
  }
}
