import { Pipe, PipeTransform } from '@angular/core';
import { NotificationDeliveryType } from '../models';

@Pipe({ name: 'notificationDeliveryType' })
export class NotificationDeliveryTypePipe implements PipeTransform {
  transform(value: NotificationDeliveryType): string {
    switch (value) {
      case NotificationDeliveryType.Email:
        return "Email";
      case NotificationDeliveryType.SMS:
        return "Text";
      default:
        return "Email";
    }
  }
}
