
import { Component, OnInit } from '@angular/core';
import { Schedule, AvailableOpportunity, Notification} from '../shared/models';
import { ActivitiesService } from './activities.service';
import { Observable, Subscribable } from 'rxjs';
import { AuthService } from '../core/authentication/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'activities',
  templateUrl: './ativities.component.html',
  styles: [('./activities.component.css')],
})

export class ActivitiesComponent implements OnInit {

  public message: string;
  public opportunities: AvailableOpportunity[] = [];
  public schedules: Schedule[];
  public showCards: boolean = true;
  public notifications: Notification[] = [];
  constructor(
    private _activitiesService: ActivitiesService,
    private _authService: AuthService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.spinner.show();
    const userId = this._authService.userId;
    this._activitiesService.getSchedules(userId).subscribe((result) => {
      this.schedules = result;
    });
    this._activitiesService.getAvailableOpportunities(userId).subscribe((result) => {
      this.opportunities = result;
    });
    this._activitiesService.getNotifications(this._authService.localUserId).subscribe((result) => {
      this.notifications = result;
    })
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  }
  getLink(event: Notification): string {
    switch (event.eventType) {
      default:
        return '/profile'
    }
  }
  confirm(notification: Notification) {
    this.spinner.show();
    console.log("Confirming notifications");
    try {
      this._activitiesService.updateNofication(notification.id).subscribe((result) => {
        this.notifications.splice(this.notifications.indexOf(notification), 1);
      });
   

    } catch (e) {

    } finally {
      this.spinner.hide();
    }

  }
}
