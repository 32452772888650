import { Component, OnInit } from '@angular/core';
//import { AuthService } from '../../core/authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-silent-callback',
  templateUrl: './auth-silent.component.html'
})
export class AuthSilentCallbackComponent {


  //constructor(private authService: AuthService) { }

  //async ngOnInit() {

  //  this.authService.signInSilent();
  //}
}
