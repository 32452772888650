import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../shared/config.data';
import { Survey, UserSurveyDto, UserProfile, FamilyMember, FamilyRequest, UserNameRequest, FamilyWidget, IStatusRequest } from '../shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class ProfileService {
  baseUrl: string;
  staffUrl: string;
  userId: number;
  constructor(protected _http: HttpClient, private _configuration: ConfigurationService) {
    this.baseUrl = _configuration.getSurveyApi();
    this.staffUrl = _configuration.getStaffApi();
    this.userId = _configuration.getUserId();
  }
  public getUserProfileCompletion(userId: string): Observable<UserSurveyDto[]> {
    let actionUrl = `${this.baseUrl}usersurvey?userId=${userId}&surveyType=2 `;
    return this._http.get<UserSurveyDto[]>(actionUrl);
  }
  public getSurveyData(): Observable<Survey[]> {
    let actionUrl = `${this.baseUrl}survey?surveyType=2&surveyState=3&isActive=true`;
    return this._http.get<Survey[]>(actionUrl);
  }
  public getFamilyWidget(userId: string): Observable<FamilyWidget> {
    let actionUrl = `${this.staffUrl}assessor/${userId}/widget/family`;
    return this._http.get<FamilyWidget>(actionUrl);
  }
  public getFamilyMembers(userId: string): Observable<FamilyMember[]> {
    let actionUrl = `${this.staffUrl}assessor/${userId}/family`;
    return this._http.get<FamilyMember[]>(actionUrl);
  }
  public requestFamilyMember(userId: string, familyRequest: FamilyRequest): Observable<number> {
    let actionUrl = `${this.staffUrl}assessor/${userId}/family/request`;
    return this._http.post<number>(actionUrl, familyRequest);
  }
  public confirmFamilyMember(userId: string, userFamilyId: number): Observable<boolean> {
    let actionUrl = `${this.staffUrl}assessor/${userId}/family/${userFamilyId}/confirm`;
    return this._http.put<boolean>(actionUrl, userFamilyId);
  }
  public deleteFamilyMember(userId: string, userFamilyId: number): Observable<boolean> {
    let actionUrl = `${this.staffUrl}assessor/${userId}/family/${userFamilyId}/remove`;
    return this._http.delete<boolean>(actionUrl);
  }
  public optOutUser(request: IStatusRequest): Observable<any> {
    let actionUrl = `${this._configuration.getStaffApi()}assessor/status`;
    return this._http.post(actionUrl, request);
  }
  public updateUserProfileData(profile: UserProfile): Observable<any> {
    let actionUrl = `${this._configuration.getStaffApi()}users/${profile.id}`;
    return this._http.put(actionUrl, profile);
  }
  public getProfileInfo(userGuid: string): Observable<UserProfile[]> {
    let actionUrl = `${this._configuration.getStaffApi()}users`;
    return this._http.get<UserProfile[]>(actionUrl, { params: { userGuid: userGuid } });
  }
  public createSurveyInstance(surveyId: number, userId: string) {
    return this._http.post<number>(`${this.baseUrl}survey/${surveyId}/user`,
      { userId: userId, surveyId: surveyId }).toPromise();
  }
  public getUsernameRequest(userId: number): Observable<UserNameRequest> {
    let actionUrl = `${this._configuration.getStaffApi()}users/${userId}/username`;
    return this._http.get<UserNameRequest>(actionUrl);
  }
  public getUsernameIsTaken(userId: number, userName: string): Observable<boolean> {
    let actionUrl = `${this._configuration.getStaffApi()}users/${userId}/username/unavailable`;
    return this._http.get<boolean>(actionUrl, { params: { userId: userId, userName: userName } });
  }
  public updateUsername(request: UserNameRequest): Observable<any> {
    let actionUrl = `${this._configuration.getStaffApi()}users/${request.userId}/username`;
    return this._http.put(actionUrl, request);
  }
}
