import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../core/authentication/auth.service';
import { RegisterRequest, RegistrationSettingsDetail, HearAboutUs } from 'src/app/shared/models';
import { RegisterService } from './register.service';
import { ToastrService } from 'ngx-toastr';
import { LookupService } from 'src/app/shared/lookup.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../../shared/config.data';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {

  success: boolean;
  error: string;
  submitted: boolean = false;
  registerRequest: RegisterRequest;
  states: string[];
  hearAboutUs: number;
  showConfirmation: number = 3;
  showAddress: number = 2;
  showUser: number = 1;
  friend: number = HearAboutUs.Friend;
  organization: number = HearAboutUs.Organization;

  registerSettings: RegistrationSettingsDetail;
  step: number;

  constructor(private authService: AuthService,
    private spinner: NgxSpinnerService,
    private registerService: RegisterService,
    private toastr: ToastrService,
    private lookupService: LookupService,
    private route: ActivatedRoute,
    private config: ConfigurationService) {
    this.step = this.showUser;

    this.registerRequest = new RegisterRequest();
    this.registerRequest.redirectUrl = window.location.origin;
    this.states = this.lookupService.getStates();
  }

  ngOnInit() {

    this.spinner.show();
    if ("" != this.config.getConfirmJs()) {
      const script = document.createElement('script');
      script.src = this.config.getConfirmJs();
      document.body.appendChild(script);

    }
    this.route.queryParams.subscribe(params => {
      this.registerRequest.referralId = params['key'];
    });
    this.registerService.getRegistrationSettings().subscribe((val) => {
      this.registerSettings = val;
      this.spinner.hide();
    });

  }

  login() {
    this.authService.login();
  }

  validate(): string {
    if ((this.registerRequest.userName === undefined || this.registerRequest.userName === null)
      || 6 > this.registerRequest.userName.length) {
      return "Please provide a user name of at least 6 characters";
    }
    if (this.registerRequest.isMale === undefined || this.registerRequest.isMale === null) {
      return "Must choose a gender";
    }
    if (this.registerRequest.isMinor() && !this.registerRequest.parentUserName) {
      return "Minors must register with parental permission. Please enter the USERNAME of the parent.";
    }
    return null;
  }

  next(stepNumber: number) {

    let parent = '';
    if (this.registerRequest.isMinor() && !this.registerRequest.parentUserName) {
      parent = prompt("Minors must register with parental permission. Please enter the USERNAME of the parent.");
      this.registerRequest.parentUserName = parent;
    }

    const message = this.validate();

    if (message) {
      this.toastr.error(message);
      return;
    }

    this.spinner.show();

    this.registerService.validateCredentials({
      confirmPassword: this.registerRequest.confirmPassword,
      dateOfBirth: this.registerRequest.dateOfBirth,
      email: this.registerRequest.email,
      firstName: this.registerRequest.firstName,
      lastName: this.registerRequest.lastName,
      password: this.registerRequest.password,
      userName: this.registerRequest.userName
    }).subscribe(result => {
      this.step = this.showAddress;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      if (error.status === 400) {
        this.toastr.error(error.error.message);
      } else {
        this.toastr.error("Error occured");
      }
    });
  }

  back(stepNumber: number) {
    this.step = stepNumber - 1;
  }

  onSubmit() {

    if (this.IsValid()) {

      this.spinner.show();
      this.registerRequest.redirectUrl = window.location.origin + "/profile";
      this.registerRequest.isMale = Number(this.registerRequest.isMale);

      if (this.hearAboutUs == this.friend) {
        this.registerRequest.organizationId = null;

      } else if (this.hearAboutUs == this.organization) {
        this.registerRequest.referralName = null;
      }
      this.registerService.createAccount(this.registerRequest).subscribe(response => {
        this.spinner.hide();
        this.toastr.success("Account created! Please check your email to validate");
        this.step = this.showConfirmation;
      }, error => {
        this.spinner.hide();
        if (error.status === 400) {
          this.toastr.error(error.error.message);
        } else {
          this.toastr.error("Error occured");
        }
      });
    }

  }

  IsValid(): boolean {

    if (!this.registerRequest.userName || 6 > this.registerRequest.userName.length) {
      this.toastr.warning("Please provide a user name of at least 6 characters");
      return false;
    }
    if (this.registerRequest.userName.indexOf(' ') >= 0) {
      this.toastr.warning("Username cannot have spaces");
      return false;
    }

    if (this.registerRequest.hearAboutUsId == this.friend && !this.registerRequest.referralName) {
      this.toastr.warning("Please provide a referral name");
      return false;
    }

    if ((this.registerRequest.address1 === undefined || this.registerRequest.address1 === null)
      || 1 > this.registerRequest.address1.trim().length) {
      this.toastr.warning("Please provide an address");
      return false;
    }

    if ((this.registerRequest.city === undefined || this.registerRequest.city === null)
      || 1 > this.registerRequest.city.trim().length) {
      this.toastr.warning("Please provide a city");
      return false;
    }

    if ((this.registerRequest.stateAbbreviation === undefined || this.registerRequest.stateAbbreviation === null)
      || 1 > this.registerRequest.stateAbbreviation.trim().length) {
      this.toastr.warning("Please provide a state");
      return false;
    }

    if ((this.registerRequest.zipCode === undefined || this.registerRequest.zipCode === null)
      || 1 > this.registerRequest.zipCode.trim().length) {
      this.toastr.warning("Please provide a zip code");
      return false;
    }

    let regex = "\\d{10}";

    if (this.registerRequest.cellPhone) {
      if (null != this.registerRequest.cellPhone && 1 < this.registerRequest.cellPhone.length) {

        this.registerRequest.cellPhone = this.registerRequest.cellPhone.replace(/\D/g, '')

        if (null != this.registerRequest.cellPhone && 1 < this.registerRequest.cellPhone.length) {
          if (!this.registerRequest.cellPhone.match(regex)) {
            this.toastr.warning("Cell Phone is not valid; must be 10 digits long, no spaces or dashes");
            return false;
          }
        }
      }
    }

    if (this.registerRequest.homePhone) {
      if (null != this.registerRequest.homePhone && 1 < this.registerRequest.homePhone.length) {

        this.registerRequest.homePhone = this.registerRequest.homePhone.replace(/\D/g, '')

        if (null != this.registerRequest.homePhone && 1 < this.registerRequest.homePhone.length) {
          if (!this.registerRequest.homePhone.match(regex)) {
            this.toastr.warning("Home Phone is not valid; must be 10 digits long, no spaces or dashes");
            return false;
          }
        }
      }
    }

    if (this.registerRequest.workPhone) {
      if (null != this.registerRequest.workPhone && 1 < this.registerRequest.workPhone.length) {

        this.registerRequest.workPhone = this.registerRequest.workPhone.replace(/\D/g, '')

        if (null != this.registerRequest.workPhone && 1 < this.registerRequest.workPhone.length) {
          if (!this.registerRequest.workPhone.match(regex)) {
            this.toastr.warning("Work Phone is not valid; must be 10 digits long, no spaces or dashes");
            return false;
          }
        }
      }
    }
    return true;
  }
}
