import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '../profile.service';
import { AuthService } from '../../core/authentication/auth.service';
import { UserProfile, IHttpError, IStatusRequest } from 'src/app/shared/models';
import { Form } from '@angular/forms';
import { LookupService } from 'src/app/shared/lookup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common'
import { NotificationDeliveryType } from '../../core/models';


@Component({
  selector: 'profile-contactinfoedit',
  templateUrl: './profile.contactinfo.edit.html'
})

export class ProfileContactInfoEditComponent implements OnInit {

  public values: any[];
  public message: string;
  public contactInfo: any;
  public states: string[];
  public notificationTypes: number[]

  public userId: number;
  public showOptOut: boolean;
  public optOutReason: number;
  public optOutSpecify: string;

  private phoneRegex = "\\d{10}";

  public contactForm: Form;
  public profile: UserProfile = {};

  constructor(
    private route: ActivatedRoute,
    private _profileService: ProfileService,
    private _authService: AuthService,
    private lookupService: LookupService,
    private spinner: NgxSpinnerService,
    private _toastr: ToastrService,
    private location: Location) {

    this.states = this.lookupService.getStates();

    this.notificationTypes = [];
    this.notificationTypes.push(<number>NotificationDeliveryType.Email);
    this.notificationTypes.push(<number>NotificationDeliveryType.SMS);
  }

  async ngOnInit() {

    let id;
    this.spinner.show();

    this.optOutReason = 0;
    this.optOutSpecify = '';
    this.showOptOut = false;

    this.profile = {} as UserProfile;
    this.userId = this._authService.localUserId;

    this.route.paramMap.subscribe(params => {
      id = params.get("id");
    });

    await this._profileService.getProfileInfo(id).subscribe((val) => {
      this.profile = val[0];
      if (this.profile) {
        if (this.profile.allowSMS === undefined || this.profile.allowSMS === null) {
          this.profile.allowSMS = false;
        }
        if (this.profile.isBadSMS === undefined || this.profile.isBadSMS === null) {
          this.profile.isBadSMS = false;
        } if (this.profile.isBadEmail === undefined || this.profile.isBadEmail === null) {
          this.profile.isBadEmail = false;
        }
        if (this.profile.twoFactorEnabled === undefined || this.profile.twoFactorEnabled === null) {
          this.profile.twoFactorEnabled = false;
        }

      }
      this.spinner.hide();
    }, (error: IHttpError) => {
      this.spinner.hide();
      this._toastr.error(error.data.message);
    });
  }

  onSubmit(form: Form) {
    if (this.isValid()) {
      this.spinner.show();
      this._profileService.updateUserProfileData(this.profile).subscribe((val) => {
        this.spinner.hide();
        this.goBack();
      }, (error: IHttpError) => {
        this.spinner.hide();
        this._toastr.error(error.error.message);
      });
    }
  }

  isPhone(phone: string): boolean {

    if (null != phone && 1 < phone.length) {

      var copy = JSON.parse(JSON.stringify(phone));

      copy = copy.replace(/\D/g, '')

      if (null != copy && 1 < copy.length) {

        if (10 != copy.length) {
          this._toastr.error("Phone numbers must be 10 digits long, no spaces or dashes");
        }

        phone = copy;
        return phone.match(this.phoneRegex).length > 0;
      }
    }
    return false;
  }

  isValid(): boolean {

    if ((this.profile.address1 === undefined || this.profile.address1 === null)
      || 1 > this.profile.address1.trim().length) {
      this._toastr.warning("Please provide an address");
      return false;
    }

    if ((this.profile.city === undefined || this.profile.city === null)
      || 1 > this.profile.city.trim().length) {
      this._toastr.warning("Please provide a city");
      return false;
    }

    if ((this.profile.state === undefined || this.profile.state === null)
      || 1 > this.profile.state.trim().length) {
      this._toastr.warning("Please provide a state");
      return false;
    }

    if ((this.profile.zipCode === undefined || this.profile.zipCode === null)
      || 1 > this.profile.zipCode.trim().length) {
      this._toastr.warning("Please provide a zip code");
      return false;
    }

    let regex = "\\d{10}";

    if (this.profile.cellPhone) {
      if (null != this.profile.cellPhone && 1 < this.profile.cellPhone.length) {

        this.profile.cellPhone = this.profile.cellPhone.replace(/\D/g, '')

        if (null != this.profile.cellPhone && 1 < this.profile.cellPhone.length) {
          if (!this.profile.cellPhone.match(regex)) {
            this._toastr.warning("Cell Phone must be 10 digits long, no spaces or dashes");
            return false;
          }
        }
      }
    } else {
      if (this.profile.preferences.reminders === NotificationDeliveryType.SMS) {
        this._toastr.warning("Cell Phone required for SMS Text Message reminders");
        return false;
      }
      if (this.profile.preferences.invitations === NotificationDeliveryType.SMS) {
        this._toastr.warning("Cell Phone required for SMS Text Message invitations");
        return false;
      }
    }

    if (this.profile.homePhone) {
      if (null != this.profile.homePhone && 1 < this.profile.homePhone.length) {

        this.profile.homePhone = this.profile.homePhone.replace(/\D/g, '')

        if (null != this.profile.homePhone && 1 < this.profile.homePhone.length) {
          if (!this.profile.homePhone.match(regex)) {
            this._toastr.warning("Home Phone must be 10 digits long, no spaces or dashes");
            return false;
          }
        }
      }
    }

    if (this.profile.workPhone) {
      if (null != this.profile.workPhone && 1 < this.profile.workPhone.length) {

        this.profile.workPhone = this.profile.workPhone.replace(/\D/g, '')

        if (null != this.profile.workPhone && 1 < this.profile.workPhone.length) {
          if (!this.profile.workPhone.match(regex)) {
            this._toastr.warning("Work Phone must be 10 digits long, no spaces or dashes");
            return false;
          }
        }
      }
    }
    return true;
  }

  goBack() {
    this.location.back();
  }

  toggleShowOptOut() {
    this.optOutReason = 0;
    this.optOutSpecify = '';
    this.showOptOut = !this.showOptOut;
  }

  onConfirm() {

    if (0 < this.optOutReason) {
      var optOutNumber = Number(this.optOutReason);
      if (9 == optOutNumber && 1 > this.optOutSpecify.length) {
        this._toastr.warning("Please specify for Other.");
        return;
      }

      this.spinner.show();

      var reason = ""
      switch (optOutNumber) {
        case 1:
          reason = 'I am moving away.';
          break;
        case 2:
          reason = 'I do not get invited to participate enough.';
          break;
        case 3:
          reason = 'I do not qualify enough for studies.'
          break;
        case 4:
          reason = 'Compensation is not high enough.'
          break;
        case 5:
          reason = 'I had a bad experience with testing or staff.'
          break;
        case 9:
          reason = this.optOutSpecify
          break;
        default:
          reason = 'User Opted Out'
          break;
      }

      var request = {
        action: 3,
        endDate: null,
        reason: reason,
        assessorId: this.userId,
        requestedBy: this.userId,
        modifiedDate: new Date()
      } as IStatusRequest;

      this._profileService.optOutUser(request).subscribe((val) => {
        this.spinner.hide();
        this._authService.logOut();
      }, (error: IHttpError) => {
        this.spinner.hide();
        this._toastr.error(error.error.message);
      });
    }
  }
}
